.social-item {
    padding-top: 24px;
    border-top: 1px solid #CCCCCC;
    width: max-content;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.social-item:hover h5 {
    text-decoration-line: underline;
}

.social-item:hover img {
    -webkit-filter: invert(1) brightness(1.4); /* safari 6.0 - 9.0 */
    filter: invert(1) brightness(1.4);
}


.social-item div h5 {
    margin: 0;
}

.social-item div .secondary {
    text-transform: lowercase;
}

.social-item img {
    padding-left: 5vw;
    transition: 0.3s;
}

@media screen and (max-device-width: 1279px) {
    .social-item {
        width: 320px;
        margin-top: 24px;
        justify-content: space-between;
    }
}

@media screen and (max-device-width: 480px) {
    .social-item {
        width: 100%;
    }
}
