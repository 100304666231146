body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "MAZIUSREVIEW-Italic";   /*Can be any text*/
  src: local("MAZIUSREVIEW20.09-Italic"),
  url("/src/assets/fonts/MAZIUSREVIEW20.09-Italic.woff") format("truetype");
}

:root {
  --background: #F2F2F2;
  --text-primary: #383838;
  --text-secondary: #747373;
  --accent: #FD622C;
  --accent-hover: #E55623;
  --accent-active: #D24613;

  --section-padding-l: 150px;
  --section-padding-m: 80px;

  --max-width-laptop: 1280px;
}
