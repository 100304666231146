a.anchor-link {
    border: none;
    text-decoration-line: underline;
    color: var(--text-primary);
    text-transform: uppercase;
}

a.anchor-link:hover {
    opacity: 0.7;
    color: var(--accent);
}
