.footer-container {
    display: flex;
    padding-top: var(--section-padding-l) - 80;
    flex-direction: column;
}

.footer-container .contact {
    display: flex;
    flex-direction: row;
}

.footer-container .contact .left {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 18px;
}

.footer-container .contact .left .row-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30%;
}

.footer-container .contact .left .row-1 .label {
    display: flex;
    flex-direction: column;
}

.footer-container .contact .left .row-2 {
    margin-top: 56px;
}

.footer-container .contact .left .row-2 a {
    background-color: var(--accent);
    color: #FFFFFF;
    text-decoration-line: none;
    text-transform: uppercase;
    padding: 20px 24px;
    border-radius: 30px;
    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: 600;
    transition: 0.3s;
}

div.secondary.label a {
    color: var(--text-secondary);
}

div.secondary.label a:hover {
    color: var(--accent);
}

.footer-container .contact .left .row-2 a:hover {
    background-color: var(--accent-hover);
}

.footer-container .contact .left .row-2 a:active {
    background-color: var(--accent-active);
}

.footer-container .contact .right {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: right;
}

.footer-container .contact .right span.footer-header {
    text-transform: uppercase;
    margin: 0;
    text-align: right;
}

.footer-container .contact .right .row-2 {
    display: flex;
    justify-content: end;
}

.footer-container .contact .right .row-2 img {
    height: 62px;
    width: 96px;
    margin-top: 18px;
    margin-left: 62px;
}

.footer-container .social {
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-container .rights {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 96px 0 44px 0;
}


@media screen and (max-device-width: 1279px) {
    .footer-container {
        padding-top: var(--section-padding-m) - 20;
    }

    .footer-container .contact .right .row-2 img {
        width: 47px;
        height: 30px;
        margin-top: 8px;
        margin-left: 28px;
    }

    .footer-container .contact .left .row-1 .label {
        margin-right: 40px;
    }

    .footer-container .contact .right {
        padding-top: 10px;
    }

    .footer-container .social {
        padding-top: 42px;
        flex-wrap: wrap;
    }

    .footer-container .rights {
        padding-top: 53px;
    }
}

@media screen and (max-device-width: 480px) {
    .footer-container .contact {
        flex-direction: column;
    }

    .footer-container .contact .right {
        order: 1;
        width: 100%;
    }

    .footer-container .contact .left {
        order: 2;
        width: 100%;
    }

    .footer-container .contact .left .row-1 .label {
        margin-right: 0;
    }

    .footer-container .contact .left .row-1 {
        padding-right: 0;
    }

    .footer-container .social {
        justify-content: center;
    }

    .footer-container .rights {
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 48px;
    }

    .footer-container .rights a{
        margin-bottom: 48px;
    }
}
