.hero-container {
    width: 100%;
}

.hero-container .content {
    display: flex;
    flex-direction: column;
    margin-top: var(--section-padding-l);
}

.hero-container .content .row-1 h1 {
    margin: 0;
}

.hero-container .content .row-2 h1 {
    margin: 0 0 0 76px;
}

.label-row {
    display: flex;
}

.header-label {
    flex-direction: column;
    display: flex;
    margin-left: 5vw;
}

.hero-container .content .row-1 h1 {
    margin-top: 0;
}

.hero-container .content .row-1 .label-row {
    margin-top: 30px;
    margin-left: auto;
}

.hero-container .content .row-1 {
    display: flex;
}

.hero-container .content .row-2 {
    display: flex;
}

.hero-container .content .row-2 img {
    margin-top: 27px;
    width: 97px;
    height: 77px;
}

.no-margin {
    margin: 0;
}

.header {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-device-width: 1279px) {
    .hero-container .content {
        margin-top: var(--section-padding-m);
    }

    .hero-container .content .row-2 img {
        width: 47px;
        height: 37px;
        margin-top: 12px;
    }

    .hero-container .content .row-2 h1 {
        margin: 0 0 0 32px;
    }

    .hero-container .content .label-row {
        display: flex;
        justify-content: space-between;
        margin: 32px 108px 0 85px;
    }

    .header-label {
        margin: 0;
    }
}

@media screen and (max-device-width: 480px) {
    .hero-container .content .label-row {
        margin: 32px 0 0 0;
    }

    .header-label {
        margin: 0;
    }

    .hero-container .content .row-2 {
        flex-wrap: wrap;
    }

    .hero-container .content .row-2 h1.no-margin {
        margin: 0;
    }

    .hero-container .content .row-2 img {
        margin-top: 8px;
    }
}

@media screen and (min-device-width: 2000px) {
    .header-label {
        margin-left: 3vw;
    }
}
