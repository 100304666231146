.experience-container {
    display: flex;
    padding-top: var(--section-padding-l);
    flex-direction: column;
}

.experience-container h2 {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

.experience-container .content {
    padding-top: 96px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-device-width: 1279px) {
    .experience-container {
        padding-top: var(--section-padding-m);
    }
    .experience-container .content {
        padding-top: 48px;
    }
}
