@import "./Styles/Typography.css";
@import "./Styles/Icons.css";

body {
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-container {
    padding: 0 6% 0 6%;
    max-width: var(--max-width-laptop);
    margin-left: auto;
    margin-right: auto;
}

a {
    transition: 0.3s;
}

a:hover {
    color: var(--accent);
}
