.icon {
    background-color: var(--text-primary);
    display: inline-block;
    mask-size: cover;
}

.img-icon {
    display: inline-block;
    background-size: cover;
    width: 56px;
    height: 56px;
}

.icon-download {
    background-color: #FFFFFF;
    mask: url("/src/assets/download.svg");
    width: 20px;
    height: 20px;
    margin-left: 8px;
    vertical-align: middle;
}


@media screen and (max-device-width: 1279px) {
    .img-icon {
        width: 40px;
        height: 40px;
    }
}

.icon.medium {
    width: 56px;
    height: 56px;
    mask-size: cover;
}

.icon.light {
    background-color: #FFFFFF;
    opacity: 0.2;
}
