.professional-experience {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    padding-bottom: 80px;
}

.professional-experience.right {
    align-self: end;
}

.professional-experience img.experience-img {
    border-radius: 40px;
}

.professional-experience div.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.professional-experience div.title a.source-website {
    color: var(--text-primary);
    text-decoration-line: none;
}

.professional-experience div.title a.source-website:hover {
    text-decoration-line: underline;
}

.professional-experience div.external-link {
    margin-top: 28px;
}

.professional-experience div.external-link a {
    color: var(--text-primary);
    text-decoration-line: none;
    transition: 0.3s;
}

.professional-experience div.external-link a:hover {
    color: var(--accent);
}

.professional-experience div.external-link a:hover img {
    -webkit-filter: invert(47%) sepia(58%) saturate(2777%) hue-rotate(343deg) brightness(101%) contrast(98%); /* safari 6.0 - 9.0 */
    filter: invert(47%) sepia(58%) saturate(2777%) hue-rotate(343deg) brightness(101%) contrast(98%);
}

.professional-experience div h3 {
    margin-top: 24px;
    margin-bottom: 12px;
}

.professional-experience div.external-link a img {
    margin-top: 3px;
    margin-left: 8px;
    transition: 0.3s;
}

.professional-experience .labels {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
}

.professional-experience span.experience-label {
    text-transform: uppercase;
    padding: 8px 16px;
    border: 1px solid var(--text-primary);
    border-radius: 20px;
    margin: 0 8px 16px 0;
}

.professional-experience span.experience-label.primary {
    border-color: var(--accent);
    color: var(--accent);
}

@media screen and (max-device-width: 1279px) {
    .professional-experience {
        padding-bottom: 48px;
    }
}

@media screen and (max-device-width: 480px) {
    .professional-experience div.external-link {
        margin-top: 8px;
    }

    .professional-experience.right {
        align-self: initial;
    }
}
