.about-container {
    padding-top: var(--section-padding-l);
    display: flex;
}

.about-container .me {
    display: inline-block;
    background-size: cover;
    height: 486px;
    width: 470px;
    background-image: url("/src/assets/Me.png");
}

.about-container .content {
    padding-right: 100px;
    width: 50%;
}

.about-container .content h3 {
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 32px;
}

.about-container .content p {
    margin-top: 0;
}

.about-container .content ul {
    padding-left: 5px;
}

.about-container .content ul li {
    list-style: none;
}

.about-container li:before {
    content: "·";
    font-size: 40px;
    vertical-align: middle;
    padding-right: 10px;
}

@media screen and (max-device-width: 1279px) {
    .about-container {
        padding-top: var(--section-padding-m);
    }

    .about-container span.me {
        display: none;
    }

    .about-container .content {
        width: 80%;
    }
}

@media screen and (max-device-width: 480px) {
    .about-container .content {
        width: 100%;
        padding-right: 0;
    }
}
