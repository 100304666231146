@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

body {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    font-family: 'Gilroy-Regular', sans-serif;
    line-height: 140%;
    letter-spacing: 0.03em;
    color: var(--text-primary);
}

p {
    font-size: 1.428em;
    line-height: 160%;
}

ul > li {
    font-size: 1.428em;
    line-height: 200%;
}

h1 {
    font-weight: 500;
    font-family: 'Gilroy-Medium', sans-serif;
    line-height: 120%;
    font-size: 8em;
}

.secondary {
    color: var(--text-secondary);
    text-transform: uppercase;
}

h1 span {
    color: var(--accent);
    font-family: 'MAZIUSREVIEW-Italic';
}

h3 {
    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: 500;
    font-size: 2.857em;
    line-height: 140%;
}

h3 span {
    color: var(--accent);
    font-family: 'MAZIUSREVIEW-Italic';
}

.italic {
    font-family: 'MAZIUSREVIEW-Italic';
}

h2 {
    font-family: 'Gilroy-Medium', sans-serif;
    line-height: 120%;
    font-weight: 500;
    font-size: 5.714em;
}

h2 span {
    color: var(--accent);
    font-family: 'MAZIUSREVIEW-Italic';
}

.footer-header {
    font-weight: 500;
    font-family: 'Gilroy-Medium', sans-serif;
    line-height: 120%;
    font-size: 6.285em;
}

h5 {
    font-family: 'MAZIUSREVIEW-Italic';
    font-size: 2.285em;
    line-height: 140%;
    text-transform: capitalize;
    font-weight: 400;
}

@media screen and (max-device-width: 1279px) {
    body {
        font-size: 0.75em;
    }
    h1 {
        font-size: 4.25em;
    }
    .footer-header {
        font-size: 3.65em;
    }
    h2 {
        font-size: 2.66em;
    }
    h3 {
        font-size: 2.3em;
    }
    h5 {
        font-size: 2em;
    }
}
